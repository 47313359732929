import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ChartComponent } from "ng-apexcharts";
import {
  OP_MATURITY_SCORING,
  OP_MATURITY_LENS_PERFORMANCE,
} from "../../../resources/Client-mock-data";
import { SurveyService } from "@app/survey/survey.service";
import { CustomService } from "@app/core/services/custom.service";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { DatabaseService } from "@app/database/database.service";
import { ClientDashboardService } from "@app/client-dashboard/client-dashboard.service";
import { _isNumberValue } from "@angular/cdk/coercion";
import { getCategoryMap, getDefinedMap } from "@app/projects-v2/util/util";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  colors: any;
  xaxis: ApexXAxis;
};

export type SingleChartOptions = {
  series2: ApexNonAxisChartSeries;
  chart2: ApexChart;
  labels2: any[];
  plotOptions2: ApexPlotOptions;
};

export type CirclesOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  plotOptions: ApexPlotOptions;
};

@Component({
  selector: "app-op-maturity-outputs",
  templateUrl: "./op-maturity-outputs.component.html",
  styleUrls: ["./op-maturity-outputs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpMaturityOutputsComponent implements OnInit {
  @Input() clientBoard: any;

  clientId: any;
  opMaturityScore: any;
  possiblePoints = 5;

  totalResponse: any = 0;
  avaCount: any = 0;
  evaCount: any = 0;
  fgCount: any = 0;
  poiCount: any = 0;
  miCount: any = 0;

  highlightedHeaders: any[] = ["Lens", "Average Score"];

  functionSwitch = false;

  teamMap = new Map();
  functionMap = new Map();

  functions: any[] = [];
  teams: any[] = [];

  selectedFunction: String = "All";
  selectedTeam: String = "All";

  selectedFunctions: any[] = ["All"];
  selectedTeams: any[] = ["All"];

  filters: any[] = [{ Value: "All" }];

  subCategoryButtons: any[] = ["All"];

  colors: any = ["#c1e6e3", "#dffcdc", "#d7e3d5", "#18eddf", "#17e8da"];

  data: any[] = [];

  functionTable: any[] = [];

  lensTable: any[] = [];
  lensTable2: any[] = [];

  subCatTable: any[] = [];
  subCatTable2: any[] = [];

  catTable: any[] = [];
  chartCategoryScore: any = new Map();

  teamTable: any[] = [];

  teamHeaders: any[] = [];
  functionHeaders: any[] = [];
  previousFunctions: any[] = ["All"];
  previousTeams: any[] = ["All"];

  @ViewChild("chart2") chart2: ChartComponent;
  public radarChartOptions: ChartOptions | any;

  @ViewChild("single") single: ChartComponent;
  public SinglechartOptions: any;

  public Radar: any;

  public chartDataArray: any = [
    {
      series: [],
      chart: {
        height: 200,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "60%",
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
              fontSize: "17px",
              fontFamily: "Hanken Grotesk",
              fontWeight: 600,
              textAlign: "center",
            },
          },
        },
      },
      colors: ["#5C2344"],
      name: "Sample",
    },
  ];

  managerCount: any = 0;
  memberCount: any = 0;
  functionsCount: any = 0;
  teamsCount: any = 0;
  overallCategoryScore: any = 0;
  overallCategories: any;

  dataTable: any[] = OP_MATURITY_SCORING;
  displayedColumns: string[] = ["team", "score"];

  lensColumns: any = ["lens", "avg-score"];
  lensHeaders: any = [];
  spinner: boolean = true;

  constructor(
    private cdr: ChangeDetectorRef,
    private surveyService: SurveyService,
    private clientService: ClientDashboardService,
    private databaseService: DatabaseService,
    private customService: CustomService
  ) {}

  ngOnInit(): void {
    this.clientId = sessionStorage.getItem("clientId") || "";
    this.opMaturityMapping();
    // this.initiateRadarChart();
    // this.initiateSingleChart();
  }

  getAllCategories() {
    this.databaseService.getCategoriesByType("CATEGORY").subscribe(
      (res: any) => {

        let cats = new Map();

        if (this.opMaturityMapData.length > 0) {
          cats = getCategoryMap(this.opMaturityMapData[0]);
        }

        let populateInitData = false;
        if (this.chartDataArray.length == 0) {
          populateInitData = true;
        }

        for (let cat of res) {
          if(cats.has(cat.category)) {
            this.chartCategoryScore.set(cats.get(cat.category), 0);
          } else {
            this.chartCategoryScore.set(cat.category, 0);
          }          
          const originalObject: any = {
            series: "",
            name: "",
          };
          if (populateInitData) {
            originalObject.series = 0;
            originalObject.name = cat.category;
            this.chartDataArray.push(originalObject);
          }
        }
        this.cdr.markForCheck();
        this.getSurveyStats();
      },
      (error) => {
        // console.log("Error from Categories API : ", error);
      }
    );
  }

  opMaturityMapData:any;
  public opMaturityMapping(){
    this.databaseService.getOpMaturyMapping(this.clientId).subscribe((res) => {
      // console.log(res,'Data res<>');
      this.opMaturityMapData = res; //DATA
      this.getAllCategories();
    })
  }

  getSurveyStats() {
    this.surveyService
      .getSurveyResponsesCount(this.clientId)
      .subscribe((count) => {
        this.spinner = true;
        for (let r of count) {
          if (r.module == "ava-eva") {
            this.avaCount = r.count;
            this.evaCount = r.count;
          } else if (r.module == "focusGroup") {
            this.fgCount = r.count;
          } else if (r.module == "processOwners") {
            this.poiCount = r.count;
          } else if (r.module == "managerInterviews") {
            this.miCount = r.count;
          }
        }
        this.totalResponse =
          this.avaCount +
          this.evaCount +
          this.miCount +
          this.fgCount +
          this.poiCount;
        this.surveyService
          .getSurveyOutputs("opMaturity", this.clientId)
          .subscribe(
            (res: any) => {
              // console.log(res);

              //Mapping 
              let changedOutputs: any = [];
              if (this.opMaturityMapData.length > 0) {
                for (let template of res) {
                  let changedData: any = [];
                  for (let data of template.modules) {
                    for (let question of data?.lens) {
                      let changedOutputData: any = { ...question };
                      let modifiedMap = getDefinedMap(this.opMaturityMapData[0],
                        { category: question.category, subCategory: question.subCategory, lens: question.lens })
                      changedOutputData.category = modifiedMap.category
                      changedOutputData.subCategory = modifiedMap.subCategory
                      changedOutputData.lens = modifiedMap.lens

                      changedData.push(changedOutputData);

                    }
                    data.lens = changedData;
                    //console.log(changedData,'cD');
                  }
                }
                //console.log(res,'res-cO');
              }  

              if (Array.isArray(res)) {
                this.data = res;
              }

              this.getFunctionsAndTeams();
              this.overAllCounts();
              this.spinner = false;
            },
            (error) => {
              // console.log("Error from Output API : ", error);
              this.spinner = false;
              let check = " RetryAfterMs";
              // console.log("error", error);
              if (error.error.message.includes(check)) {
                this.spinner = true;
                this.cdr.markForCheck();
                setTimeout(() => {
                  this.getSurveyStats();
                }, 2000);
              }
            }
          );
      });
    // this.clientService
    //   .getClientCount(this.clientId, "activity")
    //   .subscribe((res: any) => {
    //     console.log("Activity", res);
    //     const countData = res.body;
    //     this.avaCount = countData.filter(
    //       (x: { status: string }) => x.status == "Completed"
    //     ).length;
    //     this.evaCount = countData.filter(
    //       (x: { status: string }) => x.status == "Completed"
    //     ).length;
    //     this.totalResponse = this.totalResponse + this.avaCount + this.evaCount;
    //     this.clientService
    //       .getClientCount(this.clientId, "managerInterview")
    //       .subscribe((res: any) => {
    //         console.log("MI", res);
    //         const countData = res.body;
    //         this.miCount = countData.filter(
    //           (x: { status: string }) => x.status == "Complete"
    //         ).length;

    //         this.totalResponse = this.totalResponse + this.miCount;
    //         this.clientService
    //           .getClientCount(this.clientId, "focusGroup")
    //           .subscribe((res: any) => {
    //             console.log("FG", res);
    //             const countData = res.body;
    //             this.fgCount = countData.filter(
    //               (x: { status: string }) => x.status == "Complete"
    //             ).length;

    //             this.totalResponse = this.totalResponse + this.fgCount;
    //             this.clientService
    //               .getClientCount(this.clientId, "processOwner")
    //               .subscribe((res: any) => {
    //                 console.log("POI", res);
    //                 const countData = res.body;
    //                 this.poiCount = countData.filter(
    //                   (x: { status: string }) => x.status == "Complete"
    //                 ).length;

    //                 this.totalResponse = this.totalResponse + this.poiCount;

    // });
    //           });
    //       });
    //   });
  }

  getFunctionsAndTeams() {
    this.functions = [];
    this.functions.push("All");
    this.teams.push("All");
    for (let d of this.data) {
      if (!this.functions.includes(d.clientFunction)) {
        this.functions.push(d.clientFunction);
      }
      if (!this.teams.includes(d.teamName)) {
        this.teams.push(d.teamName);
      }
    }
  }

  overAllCounts() {
    this.customService.getClientFunctions(this.clientId).subscribe({
      next: (res: any) => {
        this.managerCount = 0;
        this.memberCount = 0;
        for (let r of res) {
          let fnObject: any;
          if (this.functionMap.has(r.functionName)) {
            fnObject = this.functionMap.get(r.functionName);
          } else {
            fnObject = {};
            fnObject.fte = 0;
            fnObject.ftc = 0;
            fnObject.teams = [];
          }
          for (let x of r.teams) {
            let object: any = {};
            object.functionName = r.functionName;
            object.teamName = x.teamName;
            object.fte = x.fteMembers + x.fteManagers;
            this.managerCount = this.managerCount + x.fteManagers;
            this.memberCount = this.memberCount + x.fteMembers;
            object.ftc =
              x.fteMembers * x.fteMemberCost + x.fteManagers * x.fteManagerCost;
            fnObject.fte = fnObject.fte + object.fte;
            fnObject.ftc = fnObject.ftc + object.ftc;

            fnObject.teams.push(x.teamName);
            this.teamMap.set(object.teamName, object);
          }
          this.functionMap.set(r.functionName, fnObject);
        }

        this.functionsCount = [...this.functionMap.keys()].length;
        this.teamsCount = [...this.teamMap.keys()].length;
        this.processTableData();
      },
      error: (error: any) => {
        // console.log("get counts error", error);
      },
    });
  }

  initiateRadar(series: any, cat: any) {
    // console.log(cat);
    // console.log(series);
    this.Radar = {
      series: series,
      chart: {
        height: 600,
        type: "radar",
      },
      title: {
        text: "Basic Radar Chart",
      },
      xaxis: {
        categories: cat,
      },
      yaxis: {
        min: 0,
        max: 5,
        tickAmount: 5,
        labels: {
          formatter: function (value: number) {
            return value.toFixed(1);
          },
        },
      },
      tooltip: {
        x: {
          show: false, // Hide the x-axis tooltip (series-1 label)
        },
        y: {
          formatter: function (
            value: any,
            { seriesIndex, dataPointIndex, w }: any
          ) {
            let seriesName = w.config.series[seriesIndex].name;
            // Replace "series-1" with "average"
            if (seriesName === "series-1") {
              seriesName = "average";
            }
            return seriesName + ": " + value;
          },
        },
      },
    };

    this.cdr.markForCheck();
  }

  initiateSingleChart() {
    let avgScore = 0;
    if (_isNumberValue(this.opMaturityScore)) {
      avgScore = this.opMaturityScore;
    }
    this.SinglechartOptions = {
      series: [(avgScore / this.possiblePoints) * 100],
      chart: {
        height: 300,
        type: "radialBar",
        // marginLeft: 10,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "60%",
          },
          dataLabels: {
            show: false,
            name: {
              show: false,
              fontSize: "17px",
              fontFamily: "Hanken Grotesk",
              fontWeight: 600,
              textAlign: "center",
            },
            value: {
              formatter: function (val: any) {
                return val;
                // return val.replace(/%/g, '');
              },
              show: true,
            },
          },
        },
      },
      // dataLabels: {
      //   enabled: false // <--- HERE
      // },
      colors: ["#0C0209"],
      name: avgScore,
    };
    this.cdr.markForCheck();
  }

  getRecord(data: any) {
    // console.log(data);
  }

  changeFunction(event: any) {
    // console.log("----changeFunction ------", event);
    this.selectedFunctions = [];

    if (!event.length) {
      this.selectedFunctions.push("All");
    }
    if (event.includes("All") && event.length == 1) {
      this.selectedFunctions.push("All");
    } else if (event.includes("All") && event.length > 1) {
      if (this.previousFunctions.includes("All")) {
        this.selectedFunctions.push(
          ...event.filter((x: any) => {
            return x != "All";
          })
        );
      } else {
        this.selectedFunctions.push("All");
      }
    } else {
      this.selectedFunctions.push(...event);
    }
    this.previousFunctions = this.selectedFunctions;

    this.processTableData();
  }

  changeTeam(event: any) {
    // console.log("----changeTeam ------", event);
    this.selectedTeams = [];

    if (!event.length) {
      this.selectedTeams.push("All");
    }
    if (event.includes("All") && event.length == 1) {
      this.selectedTeams.push("All");
    } else if (event.includes("All") && event.length > 1) {
      if (this.previousTeams.includes("All")) {
        this.selectedTeams.push(
          ...event.filter((x: any) => {
            return x != "All";
          })
        );
      } else {
        this.selectedTeams.push("All");
      }
    } else {
      this.selectedTeams.push(...event);
    }
    this.previousTeams = this.selectedTeams;
    this.processTableData();
  }

  processTableData() {
    let categoryMap = new Map();
    let subCategoryMap = new Map();
    let lensMap = new Map();

    for (let teamData of this.data) {
      let processThis = false;

      if (
        (this.selectedFunctions.includes(teamData.clientFunction) ||
          this.selectedFunctions.includes("All")) &&
        (this.selectedTeams.includes(teamData.teamName) ||
          this.selectedTeams.includes("All"))
      ) {
        processThis = true;
      }

      if (processThis) {
        let opMap = new Map();
        for (let module of teamData.modules) {
          if (module.module == "opMaturity") {
            for (let len of module.lens) {
              let key = len.category + "-" + len.subCategory + "-" + len.lens;
              opMap.set(key, len.answer);
            }
          }
        }
        for (let module of teamData.modules) {
          for (let len of module.lens) {
            let key = len.category + "-" + len.subCategory + "-" + len.lens;
            // console.log(len);
            if (!opMap.has(key)) {
              let answer = parseFloat(len.answer);
              let count = parseFloat(len.count);

              // Category Data
              let catObj: any;
              let catTeamObj: any = new Map();
              if (categoryMap.has(len.category)) {
                catTeamObj = categoryMap.get(len.category);
                catObj = catTeamObj.get(teamData.teamName);
              }

              if (!catObj) {
                catObj = {};
                catObj.answer = 0;
                catObj.count = 0;
              }
              catObj.answer = parseFloat(catObj.answer) + answer;
              catObj.count = catObj.count + count;

              // console.log(catObj);

              catTeamObj.set(teamData.teamName, catObj);
              categoryMap.set(len.category, catTeamObj);

              // Sub Category Data
              let subCatObj: any;
              let subCatTeamObj: any = new Map();

              if (subCategoryMap.has(len.subCategory)) {
                subCatTeamObj = subCategoryMap.get(len.subCategory);
                subCatObj = subCatTeamObj.get(teamData.teamName);
              }
              if (!subCatObj) {
                subCatObj = {};
                subCatObj.answer = 0;
                subCatObj.count = 0;
              }
              subCatObj.answer = parseFloat(subCatObj.answer) + answer;
              subCatObj.count = subCatObj.count + count;
              subCatTeamObj.set(teamData.teamName, subCatObj);
              subCategoryMap.set(len.subCategory, subCatTeamObj);

              // Lens Data
              let lensObj: any;
              let lensTeamObj: any = new Map();
              if (lensMap.has(len.lens)) {
                lensTeamObj = lensMap.get(len.lens);
                lensObj = lensTeamObj.get(teamData.teamName);
              }
              if (!lensObj) {
                lensObj = {};
                lensObj.answer = 0;
                lensObj.count = 0;
              }
              lensObj.answer = parseFloat(lensObj.answer) + answer;
              lensObj.count = lensObj.count + count;
              lensTeamObj.set(teamData.teamName, lensObj);
              lensMap.set(len.lens, lensTeamObj);
            }
          }
        }
      }
    }

    // console.log(categoryMap);

    //teamTable
    this.teamHeaders = [];
    this.teamHeaders.push("Team", "Function", "FTE", "Total FLC");
    this.teamHeaders.push(...categoryMap.keys());
    this.teamHeaders.push("Maturity Score");

    let loopTeams = [];

    if (this.selectedTeams.includes("All")) {
      loopTeams = [...this.teamMap.keys()];
    } else {
      loopTeams = this.selectedTeams;
    }
    this.teamTable = [];

    for (let team of loopTeams) {
      let obj: any = {};
      let dataObj = this.teamMap.get(team);
      obj["Team"] = team;
      obj["Function"] = dataObj.functionName;
      obj["FTE"] = dataObj.fte;
      obj["Total FLC"] = dataObj.ftc;

      let value = 0;
      let count = 0;
      for (let key of categoryMap.keys()) {
        let catObj = categoryMap.get(key);
        let xTeamObj = catObj.get(team);
        if (!xTeamObj) {
          obj[key] = 0;
        } else {
          value = xTeamObj.answer + value;
          count = count + xTeamObj.count;
          obj[key] = (xTeamObj.answer / xTeamObj.count).toFixed(1);
        }
      }

      if (value / count) {
        obj["Maturity Score"] = (value / count).toFixed(1);
        this.teamTable.push(obj);
      }
    }

    // Function Table

    this.functionHeaders = [];
    this.functionHeaders.push("Function", "FTE", "Total FLC");
    this.functionHeaders.push(...categoryMap.keys());
    this.functionHeaders.push("Maturity Score");

    let loopFunctions = [];

    if (this.selectedFunctions.includes("All")) {
      loopFunctions = [...this.functionMap.keys()];
    } else {
      loopFunctions = this.selectedFunctions;
    }

    this.functionTable = [];
    for (let fn of loopFunctions) {
      let obj: any = {};
      let dataObj = this.functionMap.get(fn);

      obj["Function"] = fn;
      obj["FTE"] = dataObj.fte;
      obj["Total FLC"] = dataObj.ftc;

      let value = 0;
      let count = 0;
      for (let team of dataObj.teams) {
        for (let key of categoryMap.keys()) {
          let catObj = categoryMap.get(key);
          let xTeamObj = catObj.get(team);
          if (!xTeamObj) {
            obj[key] = 0;
          } else {
            value = xTeamObj.answer + value;
            count = count + xTeamObj.count;
            obj[key] = (xTeamObj.answer / xTeamObj.count).toFixed(1);
          }
        }
      }

      if (value / count) {
        obj["Maturity Score"] = (value / count).toFixed(1);
        this.functionTable.push(obj);
      }
    }

    this.functionTable.forEach(element => {
     this.addCommasToNumberInObject(element,'Total FLC')
    });

    // console.log(this.functionTable);

    //Category Map Table
    let sum = 0;
    let total = 0;
    this.catTable = [];
    for (let key of this.chartCategoryScore.keys()) {
      let catObj: any = {};
      catObj.name = key;
      if (categoryMap.has(key)) {
        let teamData = categoryMap.get(key);
        // console.log(key);
        // console.log(teamData);
        let cSum = 0;
        let cTot = 0;
        for (let t of teamData.keys()) {
          let tDat = teamData.get(t);
          cSum = cSum + tDat.answer;
          cTot = cTot + tDat.count;
          sum = sum + tDat.answer;
          total = total + tDat.count;
        }
        catObj.value = cSum / cTot;
      } else {
        catObj.value = 0;
      }
      this.catTable.push(catObj);
    }

    let resulttt: any = [];

    this.catTable.forEach((ele: any, index: any) => {
      // console.log(ele);

      const originalObject: any = {
        series: "",
        name: "",
      };
      originalObject.series = parseFloat(ele.value.toFixed(1));
      originalObject.name = ele.name;

      resulttt.push(originalObject);
    });
    this.chartDataArray = resulttt;
    this.cdr.markForCheck();
    // console.log("chartsarray", this.chartDataArray);
    this.opMaturityScore = (sum / total).toFixed(1);

    //sub Category Table

    this.subCatTable = [];

    for (let subCat of subCategoryMap.keys()) {
      let subObj: any = {};
      subObj.subCategory = subCat;
      let cSum = 0;
      let cTot = 0;
      let teamData = subCategoryMap.get(subCat);
      for (let t of teamData.keys()) {
        let tDat = teamData.get(t);
        cSum = cSum + tDat.answer;
        cTot = cTot + tDat.count;
        subObj[t] = (tDat.answer / tDat.count).toFixed(1);
      }
      subObj.value = (cSum / cTot).toFixed(1);
      this.subCatTable.push(subObj);
    }

    let keyData: any = [];
    let valueData: any = [];
    this.subCatTable.forEach((res) => {
      keyData.push(res.subCategory);
      valueData.push(res.value);
    });

    // console.log("KV", keyData, valueData);

    this.initiateRadar([{ name: "All", data: valueData }], keyData);
    this.cdr.markForCheck();

    //Lens Table
    this.lensHeaders = [];
    this.lensHeaders.push("Lens");
    this.lensHeaders.push(...this.teamMap.keys());
    this.lensHeaders.push("Average Score");

    this.lensTable = [];

    for (let lens of lensMap.keys()) {
      let subObj: any = {};
      subObj["Lens"] = lens;
      let cSum = 0;
      let cTot = 0;
      let teamData = lensMap.get(lens);
      for (let t of teamData.keys()) {
        let tDat = teamData.get(t);
        cSum = cSum + tDat.answer;
        cTot = cTot + tDat.count;
        subObj[t] = (tDat.answer / tDat.count).toFixed(1);
      }
      subObj["Average Score"] = (cSum / cTot).toFixed(1);
      this.lensTable.push(subObj);
    }

    this.lensTable2 = this.lensTable;
    // console.log(this.lensTable);
    this.initiateSingleChart();
    this.cdr.markForCheck();
  }

  addCommasToNumberInObject(obj:any, key:any) {
    if (obj.hasOwnProperty(key) && typeof obj[key] === 'number') {
        obj[key] = obj[key].toLocaleString();
    }
    return obj;
}

  onFunctionChange(value: any) {
    //this.functionSwitch = $event.checked;
    if (value == "team") {
      this.functionSwitch = false;
    } else {
      this.functionSwitch = true;
    }
  }

  getColor(rating: any) {
    if (typeof rating === "string") {
      rating = parseFloat(rating);
      if (rating) {
        if (rating >= 1.0 && rating < 2.0) {
          return "red";
        } else if (rating >= 2.0 && rating < 3.0) {
          return "brown";
        } else if (rating >= 3.0 && rating < 4.0) {
          return "black";
        } else if (rating >= 4.0 && rating < 5.0) {
          return "green";
        } else if (rating >= 5.0) {
          return "#90EE90";
        }
      }
    }
    return "black";
  }

  lensCheck(header: any): any {
    if (this.highlightedHeaders.includes(header)) {
      return true;
    } else {
      return false;
    }
  }

  isRowSelected(row: any) {
    let key = row.Team ? row.Team : row.Function;
    return this.highlightedHeaders.includes(key);
  }

  onRowClick(row: any) {
    let key = row.Team ? row.Team : row.Function;
    if (this.highlightedHeaders.includes(key)) {
      this.highlightedHeaders = this.highlightedHeaders.filter((x) => x != key);
    } else {
      this.highlightedHeaders.push(key);
    }
    this.renderCharts();
    this.renderLensTable();
  }

  renderCharts() {
    if (this.highlightedHeaders.length == 2) {
      let keyData: any = [];
      let valueData: any = [];
      this.subCatTable.forEach((res) => {
        keyData.push(res.subCategory);
        valueData.push(res.value);
      });

      // console.log("KV", keyData, valueData);

      this.initiateRadar([{ name: "All", data: valueData }], keyData);
      this.cdr.markForCheck();
    } else {
      let series: any = [];
      let keyData: any = [];

      // console.log(this.subCatTable);

      this.subCatTable.forEach((res) => {
        keyData.push(res.subCategory);
      });

      for (let head of this.highlightedHeaders) {
        let obj: any = {};
        if (this.functionSwitch) {
          if (this.functionMap.has(head)) {
            obj.name = head;
            let fnObj = this.functionMap.get(head);
            let data: any = [];
            let val = 0;
            let tot = 0;
            for (let c of keyData) {
              for (let sc of this.subCatTable) {
                for (let t of fnObj.teams) {
                  if (sc[t] && c == sc.subCategory) {
                    val = val + parseFloat(sc[t]);
                    tot = tot + 1;
                    break;
                  }
                }
              }
              if (tot > 0) {
                data.push((val / tot).toFixed(1));
              } else {
                data.push(undefined);
              }
              val = 0;
              tot = 0;
              obj.data = data;
            }
            series.push(obj);
          }
        } else {
          if (this.teamMap.has(head)) {
            obj.name = head;
            let data: any = [];
            let val = 0;
            let tot = 0;
            // console.log(head);
            // console.log(keyData);
            // console.log(this.subCatTable);
            for (let c of keyData) {
              for (let sc of this.subCatTable) {
                // console.log(sc);
                if (sc[head] && c == sc.subCategory) {
                  val = val + parseFloat(sc[head]);
                  tot = tot + 1;
                  break;
                }
              }
              if (tot > 0) {
                data.push((val / tot).toFixed(1));
              } else {
                data.push(undefined);
              }
              val = 0;
              tot = 0;
              obj.data = data;
            }
            series.push(obj);
          }
        }
        // console.log(series);
        this.initiateRadar(series, keyData);
        this.cdr.markForCheck();
        // for (let d of data) {
        // }
      }
    }
  }

  getBarColor(value: number): string {
    const fillValue = Math.min(value / 5, 1) * 100;
    return `linear-gradient(90deg, black ${fillValue}%, white ${
      100 - fillValue
    }%)`;
  }

  renderLensTable() {
    if (this.highlightedHeaders.length == 2) {
      this.lensHeaders = [];
      this.lensHeaders.push("Lens");
      this.lensHeaders.push("Average Score");
      this.lensTable = this.lensTable2;
    } else {
      let data: any = [];
      this.lensHeaders = [];
      this.lensHeaders.push("Lens");

      if (this.functionSwitch) {
        this.lensHeaders.push(...this.functionMap.keys());
        this.lensHeaders.push("Average Score");
      } else {
        this.lensHeaders.push(...this.teamMap.keys());
        this.lensHeaders.push("Average Score");
      }

      for (let len of this.lensTable2) {
        let obj: any = {};
        obj["Lens"] = len["Lens"];
        let val = 0;
        let tot = 0;

        for (let head of this.highlightedHeaders) {
          if (this.functionSwitch) {
            if (this.functionMap.has(head)) {
              let fn = this.functionMap.get(head);
              let tVal = 0;
              let tTot = 0;
              for (let t of fn.teams) {
                if (len[t]) {
                  tVal = tVal + parseFloat(len[t]);
                  tTot = tTot + 1;
                }
              }
              val = val + tVal;
              tot = tot + tTot;
              obj[head] = tTot > 0 ? (tVal / tTot).toFixed(1) : undefined;
            }
          } else {
            if (this.teamMap.has(head)) {
              if (len[head]) {
                obj[head] = len[head];
                val = val + parseFloat(len[head]);
                tot = tot + 1;
              }
            }
          }
        }
        obj["Average Score"] = tot > 0 ? (val / tot).toFixed(1) : undefined;
        data.push(obj);
      }
      this.lensTable = data;
    }
  }
}
