import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { ManagerInterviewService } from "../manager-interview.service";
import { MatTableDataSource } from "@angular/material/table";
import { SurveyService } from "@app/survey/survey.service";
import { Sort } from "@angular/material/sort";
import { ResponseEditComponent } from "@app/core/response-edit/response-edit.component";
@Component({
  selector: "app-response-management",
  templateUrl: "./response-management.component.html",
  styleUrls: ["./response-management.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponseManagementComponent implements OnInit {
  clientId: string;
  datasource1 = new MatTableDataSource<any>();
  dc = ["candidate", "interviewer", "team", "dueDate", "status", "action"];
  surveyData: any;
  surveyId: any;
  surveyDetailList: any;

  responseData = [
    {
      _id: "648ae2cd4ad1e44ab4e41c4f",
      clientId: "647dba3941826b3aa21cc9e2",
      isManager: true,
      surveyData: {
        templates: [
          {
            templateId: "64741118a66d862ec8defbbd",
            module: "managerInterviews",
          },
        ],
        startDate: "2023-06-15T10:07:08.935Z",
        endDate: "2023-06-15T10:07:08.935Z",
        user: "64741111809eea89388fd603",
      },
      createdAt: "2023-06-15T10:07:09.001Z",
      link: "http://localhost:4200/submit-survey/648ae2cd4ad1e44ab4e41c4f",
      updatedAt: "2023-06-15T10:07:09.031Z",
      module: "managerInterviews",
      clicks: 4,
      interviewData: {
        _id: "64822490ab9f48a1dec2b28f",
        interviewName: "Venkat",
        clientFunction: "Accounting/Finance",
        teamName: "Alpha",
        role: "Delivery Manager",
        scheduledDate: "2023-06-10T19:01:26.933Z",
        interviewer: "admin admin",
        templateId: "6483329cdb4af57abeb73d68",
      },
    },
    {
      _id: "648ae2cd4ad1e44ab4e41c4f",
      clientId: "647dba3941826b3aa21cc9e2",
      isManager: true,
      surveyData: {
        templates: [
          {
            templateId: "64741118a66d862ec8defbbd",
            module: "managerInterviews",
          },
        ],
        startDate: "2023-06-15T10:07:08.935Z",
        endDate: "2023-06-15T10:07:08.935Z",
        user: "64741111809eea89388fd603",
      },
      createdAt: "2023-06-15T10:07:09.001Z",
      link: "http://localhost:4200/submit-survey/648ae2cd4ad1e44ab4e41c4f",
      updatedAt: "2023-06-15T10:07:09.031Z",
      module: "managerInterviews",
      clicks: 4,
      interviewData: {
        _id: "64822490ab9f48a1dec2b28f",
        interviewName: "Pradeep",
        clientFunction: "Accounting/Finance",
        teamName: "Beta",
        role: "Delivery Manager",
        scheduledDate: "2023-06-10T19:01:26.933Z",
        interviewer: "admin admin",
        templateId: "6483329cdb4af57abeb73d68",
      },
    },
    {
      _id: "648ae2cd4ad1e44ab4e41c4f",
      clientId: "647dba3941826b3aa21cc9e2",
      isManager: true,
      surveyData: {
        templates: [
          {
            templateId: "64741118a66d862ec8defbbd",
            module: "managerInterviews",
          },
        ],
        startDate: "2023-06-15T10:07:08.935Z",
        endDate: "2023-06-15T10:07:08.935Z",
        user: "64741111809eea89388fd603",
      },
      createdAt: "2023-06-15T10:07:09.001Z",
      link: "http://localhost:4200/submit-survey/648ae2cd4ad1e44ab4e41c4f",
      updatedAt: "2023-06-15T10:07:09.031Z",
      module: "managerInterviews",
      clicks: 4,
      interviewData: {
        _id: "64822490ab9f48a1dec2b28f",
        interviewName: "Haneefa",
        clientFunction: "Accounting/Finance",
        teamName: "Gama",
        role: "Member",
        scheduledDate: "2023-06-10T19:01:26.933Z",
        interviewer: "admin admin",
        templateId: "6483329cdb4af57abeb73d68",
      },
    },
  ];

  interval: any;

  //new
  interviewCount: any = 0;
  functionsCount: any;
  teamsCount: any;
  completedCount: any = 0;
  inCompleteCount: any = 0;

  constructor(
    private activatedroute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private matdialog: MatDialog,
    private interviewService: ManagerInterviewService,
    private snackbar: MatSnackBar,
    private http: HttpClient,
    private surveyService: SurveyService
  ) {
    let url = this.router.url;
    let splitUrl = url.split("/");
    this.clientId = splitUrl[2];
    // console.log("clientId ", this.clientId);
  }
  ngOnInit(): void {
    this.getInterviewResponse();

    this.interval = setInterval(() => {
      this.getInterviewResponse();
    }, 5000);
  }

  getInterviewResponse() {
    this.interviewService
      .getManagerInterviewResponse("managerInterviews", this.clientId)
      .subscribe({
        next: (res: any) => {
          // console.log("mi response", res);
          if (res.length > 0) {
            let interview: any[] = [];
            let functions: any = [];
            let teams: any = [];
            this.completedCount = 0;
            this.inCompleteCount = 0;
            res.forEach((element: any) => {              
              (element.interviewData["clicks"] = element.clicks),
                (element.interviewData["link"] = element.link);
              if (element.interviewData.interviewName) {
                functions.push(element.clientFunction);
                teams.push(element.teamName);
              }
            });
            res.forEach((element: any) => {              
              if (element.clicks !== 0) {
                element.interviewData.status = "Complete";
              } else {
                element.interviewData.status = "Incomplete";
              }
            });

            interview = res.map((x: any) => x.interviewData);
            let filteredData: any = [];
            for (let i of interview) {
              if (i.interviewName) {
                filteredData.push(i);
              }
            }
            filteredData.forEach((element: any) => {
              if (element.status === "Complete") {
                this.completedCount = this.completedCount + 1;
              } else {
                this.inCompleteCount = this.inCompleteCount + 1;
              }
            });
            let uniqueFunctions = [...new Set(functions)];
            this.interviewCount = filteredData.length;
            this.functionsCount = uniqueFunctions.length;
            this.teamsCount = [...new Set(teams)].length;
            this.datasource1.data = filteredData;
            this.datasource1.data.filter((x) => {
              if (x.interviewName) return x;
            });
            this.responseData = interview;
            this.cdr.markForCheck();
            // console.log("interview", interview);
          } else {
            this.datasource1.data = [];
            this.cdr.markForCheck();
            //this.snackbar.open('No Data Found', 'Close')
          }
        },
        error: (error: any) => {
          // console.log("mi response error", error);
        },
      });
  }

  goToSurveySubmit(element: any) {
    // console.log("link", element.link);
    window.open(element.link);
  }

  /* TODO: sort functionality will move to common service.  */
  sortData(sort: Sort) {
    const data = this.responseData.slice();
    if (!sort.active || sort.direction === "") {
      this.datasource1.data = data;
      return;
    }

    this.datasource1.data = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "candidate":
          return this.compare(a.candidate, b.candidate, isAsc);
        case "team":
          return this.compare(a.team, b.team, isAsc);
        case "function":
          return this.compare(a.function, b.function, isAsc);
        case "team-manager":
          return this.compare(a["team-manager"], b["team-manager"], isAsc);
        case "date":
          return this.compare(a.date, b.date, isAsc);
        case "interviewer":
          return this.compare(a.interviewer, b.interviewer, isAsc);
        case "template":
          return this.compare(a.template, b.template, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  copyLink(element: any) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    element.link = element.link+"?clientId="+element.clientId;
    selBox.value = element.link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  editResponse(element: any) {
    // console.log("link", element);
    this.surveyService.getSurveyDetails("interviewId", element._id).subscribe(
      (res: any) => {
        this.surveyService.getSurveyResponses(res[0]._id).subscribe(
          (res2: any) => {
            // console.log("RES", res, res2.data[0]);
            let dialog = this.matdialog.open(ResponseEditComponent, {
              width: "95em",
              // height: '750px',
              disableClose: false,
              data: res2.data[0],
            });
          },
          (err2: any) => {
            this.snackbar.open(err2.message, "close");
          }
        );
      },
      (err: any) => {
        this.snackbar.open(err.message, "close");
      }
    );
  }

  getDueDate(date: string) {
    return (
      date.substring(8, 10) +
      "-" +
      date.substring(6, 8) +
      "-" +
      date.substring(0, 4)
    );
  }
}
